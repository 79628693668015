import { FormConfiguration } from 'lib/types/entities/field.definition';

export const fields: FormConfiguration = [
  {
    name: 'email',
    type: 'text',
    label: '',
    placeholder: 'Email',
    value: '',
    isRequired: true,
    validate: (value, field) => {
      const validationErrors = [];

      if (!value && field.isRequired) {
        validationErrors.push('Email is required.');
      }

      return validationErrors.length > 0 ? validationErrors : undefined;
    },
  },
];
