import React from 'react';
import settings from 'config/settings/settings.config';

import {
  useAuth,
  VIEWS,
} from 'providers/authentication/authentication.provider';

import { Header } from 'containers/header/header.component';
import { Login } from 'components/login/login.component';
import { ForgottenPassword } from 'components/forgotten-password/forgotten.component';
import { ResetPassword } from 'components/password-reset/reset.component';

import styles from './auth.module.css';

export const AuthLayout = ({ view }: { view: string | null }) => {
  return (
    <div>
      <Header title={settings.title} />
      <div className={styles.container}>
        <div className={styles.inner}>
          <AuthView view={view} />
        </div>
      </div>
    </div>
  );
};

const AuthView = ({ view: initialView }: { view: string | null }) => {
  let { view } = useAuth();

  if (initialView) {
    view = initialView;
  }

  switch (view) {
    case VIEWS.UPDATE_PASSWORD:
      return <ResetPassword />;
    case VIEWS.FORGOTTEN_PASSWORD:
      return <ForgottenPassword />;
    default:
      return <Login />;
  }
};
