import { FormEvent, useState } from 'react';
import { useRouter } from 'next/router';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import {
  useAuth,
  VIEWS,
} from 'providers/authentication/authentication.provider';

import {
  hasError,
  mapFieldIdentifierToFieldComponent,
} from 'lib/content/field-resolver.util';

import { fields } from './login.config';

import { Fieldset } from 'components/fieldset/fieldset.component';
import { Button } from 'components/button/button.component';

import { ButtonProps } from 'components/button/button.definition';
import { Database } from 'lib/models/application/supabase.types';

export function Login() {
  const router = useRouter();
  const { setView } = useAuth();

  // TODO: Use error messages instead of boolean
  const [errors, setErrors] = useState<{ [index: string]: boolean }>({});
  const [error, setError] = useState<string | null>(null);

  const [userData, setUserData] = useState({ email: '', password: '' });

  const [buttonText, setButtonText] = useState('Login');
  const [buttonProps, setButtonProps] = useState<ButtonProps>({
    theme: 'default',
    icon: null,
    disabled: false,
  });

  const onChange = (
    field: string,
    {
      value,
      validationErrors,
    }: {
      value: unknown;
      validationErrors: string[] | undefined;
    }
  ) => {
    const _errors = { ...errors };
    _errors[field] = !!validationErrors;
    setErrors(_errors);
    setUserData({ ...userData, [field]: value });
  };

  const doLogin = (event: FormEvent) => {
    setButtonProps({
      theme: 'default',
      icon: null,
      disabled: true,
    });

    setButtonText('Logging in...');
    handleSubmit(event);
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const supabase = createBrowserSupabaseClient<Database>();
    const { data, error } = await supabase.auth.signInWithPassword({
      ...userData,
    });

    if (data) {
      router.push('/jobs');
    }

    if (error) {
      setButtonProps({
        theme: 'error',
        icon: null,
        disabled: false,
      });

      setButtonText('Please try again...');
      setError(error.message);
    }
  }

  // async function sendInvite() {
  //   const supabase = createBrowserSupabaseClient<Database>();
  //   const { data, error } = await supabase.auth.admin.inviteUserByEmail(
  //     userData.email
  //   );

  //   console.log(data);
  //   console.log(error);
  // }

  return (
    <form onSubmit={doLogin}>
      {fields.map((field) => {
        const Field: React.FC<any> | undefined =
          mapFieldIdentifierToFieldComponent(field.type);
        const defaultValue = field.value || '';

        if (typeof Field !== 'undefined') {
          return (
            <Fieldset
              key={field.name}
              label={field.label}
              hasError={hasError(field.name, errors)}
            >
              <Field
                name={field.name}
                field={field}
                value={defaultValue}
                placeholder={field.placeholder}
                type={field.type}
                onChange={({
                  value,
                  validationErrors,
                }: {
                  value: unknown;
                  validationErrors: string[] | undefined;
                }) => {
                  onChange(field.name, { value, validationErrors });
                }}
              />
            </Fieldset>
          );
        }

        return undefined;
      })}
      {error && <p color="danger">{error}</p>}

      {/* TODO: Resend password confirmation link */}
      {/* <Button
        type="submit"
        theme="pending"
        icon={null}
        disabled={false}
        onClick={() => sendInvite()}
      >
        Resend confirmation email
      </Button> */}

      {/* TODO: Add loading wheel and disable onClick/isLoading */}
      <Button type="submit" {...buttonProps}>
        {buttonText}
      </Button>

      {/* TODO: Fix update password view */}
      {/* <p className="anchor" onClick={() => setView(VIEWS.FORGOTTEN_PASSWORD)}>
        Forgotten your password?
      </p> */}
    </form>
  );
}
