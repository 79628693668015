import { FormEvent, useState } from 'react';
import { useRouter } from 'next/router';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';

import {
  hasError,
  mapFieldIdentifierToFieldComponent,
} from 'lib/content/field-resolver.util';

import { fields } from './reset.config';

import { Fieldset } from 'components/fieldset/fieldset.component';
import { Button } from 'components/button/button.component';

import { ButtonProps } from 'components/button/button.definition';
import { Database } from 'lib/models/application/supabase.types';

// TODO: Use same form builder for all forms - Pass config as props to trigger rerender

export function ResetPassword() {
  const router = useRouter();

  // TODO: Use error messages instead of boolean
  const [errors, setErrors] = useState<{ [index: string]: boolean }>({});
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState({ password: '' });

  const [buttonText, setButtonText] = useState('Reset password');
  const [buttonProps, setButtonProps] = useState<ButtonProps>({
    theme: 'default',
    icon: null,
    disabled: false,
  });

  const onChange = (
    field: string,
    {
      value,
      validationErrors,
    }: {
      value: unknown;
      validationErrors: string[] | undefined;
    }
  ) => {
    const _errors = { ...errors };
    _errors[field] = !!validationErrors;
    setErrors(_errors);
    setFormData({ ...formData, [field]: value });
  };

  const doLogin = (event: FormEvent) => {
    setButtonProps({
      theme: 'default',
      icon: null,
      disabled: true,
    });

    setButtonText('Resetting password...');
    handleSubmit(event);
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const supabase = createBrowserSupabaseClient<Database>();
    const { data, error } = await supabase.auth.updateUser({
      password: formData.password,
    });

    if (data) {
      setButtonProps({
        theme: 'success',
        icon: null,
        disabled: true,
      });
      setButtonText('Password reset!');
      router.push('/jobs');
    }

    if (error) {
      setError(error.message);
    }
  }

  return (
    <form onSubmit={doLogin}>
      {error && <span color="danger">{error}</span>}
      {fields.map((field) => {
        const Field: React.FC<any> | undefined =
          mapFieldIdentifierToFieldComponent(field.type);
        const defaultValue = field.value || '';

        if (typeof Field !== 'undefined') {
          return (
            <Fieldset
              key={field.name}
              label={field.label}
              hasError={hasError(field.name, errors)}
            >
              <Field
                name={field.name}
                field={field}
                value={defaultValue}
                placeholder={field.placeholder}
                type={field.type}
                onChange={({
                  value,
                  validationErrors,
                }: {
                  value: unknown;
                  validationErrors: string[] | undefined;
                }) => {
                  onChange(field.name, { value, validationErrors });
                }}
              />
            </Fieldset>
          );
        }

        return undefined;
      })}
      {/* TODO: Add loading wheel and disable onClick/isLoading */}
      <Button type="submit" {...buttonProps}>
        {buttonText}
      </Button>

      <p className="anchor" onClick={() => router.push('/')}>
        Return to login.
      </p>
    </form>
  );
}
